import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {LABEL_TH} from "./translation/th";
import {LABEL_EN} from "./translation/en";
import {LABEL_LA} from "./translation/la";
import {LABEL_MM} from "./translation/mm";
import {LABEL_KH} from "./translation/kh";

const resources = {
    en: { translation: LABEL_EN },
    th: { translation: LABEL_TH},
    la: { translation: LABEL_LA},
    mm: { translation: LABEL_MM},
    kh: { translation: LABEL_KH},
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        supportedLngs: ['en','th','la','mm','kh'],
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;
