import {
  DATETIME_FORMAT_DB,
  DATETIME_FORMAT_DISPLAY,
  DATE_FORMAT_DB,
  DATE_FORMAT_DISPLAY,
  supportedLngs,
  agentStatus,
  agentBetBillDelStatus,
  subAgentStatus,
  memberStatus,
  memberAcceptStatus,
  billAcceptStatus,
  transferType,
  billStatus,
  playStatus,
  scoreStatus,
  BET_GROUP_DP,
  BET_GROUP_OU,
  BET_GROUP_1X2,
  BET_GROUP_OE,
  betGroups,
  betTypes,
  DATE_FORMAT_DB_MG,
  DATETIME_FORMAT_DB_MG,
  kickTime, oddsLineType,
  translateSlip, playTypePrice, BILL_STATUS_WAIT
} from "../constants";
import moment from "moment";
import i18n from '../../i18n'
import QRCode from "react-qr-code";
import ReactDOMServer from "react-dom/server";
// const {t} = useTranslation()

export function clearLocalStorage()
{
  localStorage.removeItem('X_AG_LOGIN')
  localStorage.removeItem('X_AG_CUR_TOKEN')
}

export function searchModel()
{
  const data = {
    page: 1,
    per_page: 50,
    count_row: 0
  }

  let perPage = localStorage.getItem('PER_PAGE');
  if(!perPage){
    perPage = 50;
    localStorage.setItem('PER_PAGE', perPage);
  }

  data.per_page = parseInt(perPage, 10)
  return data
}

export function empty(value)
{
  if(typeof value === 'undefined') {
    return true;
  }
  else if(Number.isNaN(value)) {
    return true;
  }
  else if(value === null || value===false || value===0 || value==='0' || value==='') {
    return true;
  }
  else if(typeof value ==='object' && Object.keys(value).length === 0 && Object.getPrototypeOf(value) === Object.prototype) {
    return true;
  }
  else if(value instanceof Array ) {
    return value.length < 1;
  }
  else{
    return false;
  }
}

export function intVal(strVal)
{
    if(strVal && strVal.length > 0) {
        return parseInt(strVal, 10);
    }
    else{
        return strVal;
    }
}

export function numberF(number, digits = 0)
{
  number = Number(number)
  const arr = number.toString().split('.')
  const numberInt = parseInt(number)
  if(arr.length > 1){
    const decimalNum = (arr[1]).toString().substring(0, digits)
    number = Number(`${numberInt}.${decimalNum}`)
  }  

  const f = Intl.NumberFormat('th-TH', {
    minimumFractionDigits: digits,
    // maximumFractionDigits: digits,
  })

  return  f.format(number);
}

// export function numberF(number, digits = 0)
// {
//   if(number === null){
//     return ''
//   }

//   const f = Intl.NumberFormat('th-TH', {
//     minimumFractionDigits: digits,
//     maximumFractionDigits: digits,
//   })

//   return  f.format(number);
// }

export function dateFDB(date, sourceFormat = '')
{
  if(!empty(date)){
    return moment(date, sourceFormat).format(DATE_FORMAT_DB)
  }

  return '';
}

export function dateTimeFDB(dateTime, sourceFormat = '')
{
  if(!empty(dateTime)){
    return moment(dateTime, sourceFormat).format(DATETIME_FORMAT_DB)
  }

  return '';
}

export function dateFDBMG(date, sourceFormat = '')
{
  if(!empty(date)){
    return moment(date, sourceFormat).format(DATE_FORMAT_DB_MG)
  }

  return '';
}

export function dateTimeFDBMG(dateTime, sourceFormat = '')
{
  if(!empty(dateTime)){
    return moment(dateTime, sourceFormat).format(DATETIME_FORMAT_DB_MG)
  }

  return '';
}

export function dateF(date, format = DATE_FORMAT_DISPLAY, sourceFormat = '')
{
  if(!empty(date)){
    return moment(date, sourceFormat).format(format)
  }

  return '';
}

export function dateTimeF(dateTime, format = DATETIME_FORMAT_DISPLAY, sourceFormat = '')
{
  if(!empty(dateTime)){
    return moment(dateTime, sourceFormat).format(format)
  }

  return '';
}

export function unInteger(obj, fieldName)
{
  if(obj[fieldName]) {
    let val = obj[fieldName];
    val = val.replace(/\D/g, '');
    obj[fieldName] = val;
  }
}

export function rangeNum(start, end) {
  if(start > end){
    return []
  }
  return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

export function langName(code)
{
  for(let row of supportedLngs) {
    if(row.code === code) {
      return row.name
    }
  }

  return '';
}

export function langFlag(code)
{
  for(let row of supportedLngs) {
    if(row.code === code) {
      return row.flag
    }
  }

  return '';
}

export function memberStatusDisplay(code, getColor = false)
{
  for(let row of memberStatus) {
    if(row.code === code) {
      if(getColor){
        return row.color
      }
      else {
        return i18n.t(row.name)
      }
    }
  }

  return '';
}

export function memberAcceptStatusDisplay(code, getColor = false)
{
  for(let row of memberAcceptStatus) {
    if(row.code === code) {
      if(getColor){
        return row.color
      }
      else {
        return i18n.t(row.name)
      }
    }
  }

  return '';
}

export function billAcceptStatusDisplay(code, getColor = false)
{
  for(let row of billAcceptStatus) {
    if(row.code === code) {
      if(getColor){
        return row.color
      }
      else {
        return i18n.t(row.name)
      }
    }
  }

  return '';
}

export function agentStatusDisplay(code, getColor = false)
{
  for(let row of agentStatus) {
    if(row.code === code) {
      if(getColor){
        return row.color
      }
      else {
        return i18n.t(row.name)
      }
    }
  }

  return '';
}

export function oddsLineTypeDisplay(code, getColor = false)
{
  for(let row of oddsLineType) {
    if(row.code === code) {
      if(getColor){
        return row.color
      }
      else {
        return row.name
      }
    }
  }

  return '';
}

export function agentBetBillDelStatusDisplay(code, getColor = false)
{
  for(let row of agentBetBillDelStatus) {
    if(row.code === code) {
      if(getColor){
        return row.color
      }
      else {
        return i18n.t(row.name)
      }
    }
  }

  return '';
}

export function subAgentStatusBadge(code)
{
  for(let row of subAgentStatus) {
    if(row.code === code) {
      return <span className={`badge bg-${row.color} w-100`}>{i18n.t(row.name)}</span>
    }
  }

  return ''
}

export function transferTypeDisplay(code, getColor = false)
{
  for(let row of transferType) {
    if(row.code === code) {
      if(getColor){
        return row.color
      }
      else {
        return i18n.t(row.name)
      }
    }
  }

  return '';
}

export function billStatusDisplay(code, color = false)
{
    for(let row of billStatus) {
        if(row.code === code) {
            if(color){
                return row.color
            }
            else {
                return i18n.t(row.name)
            }
        }
    }

    return ''
}

export function playStatusDisplay(code, color= false)
{
    for(let row of playStatus) {
        if(row.code === code) {
            if(color){
                return row.color
            }
            else {
                return i18n.t(row.name)
            }
        }
    }

    return ''
}

export function scoreStatusDisplay(code, getColor = false)
{
  for(let row of scoreStatus) {
    if(row.code === code) {
      if(getColor){
        return row.color
      }
      else {
        return i18n.t(row.name)
      }
    }
  }

  return '';
}

export function getBetType(code , field)
{
  for(let row of betTypes) {
    if(row.code === code) {
      if(!empty(field)){
        return row[field]
      }
      else {
        return row
      }
    }
  }
  if(!empty(field)){
    return '';
  }
  else {
    return {code: '', group: '', halftime: '', team: ''}
  }
}

export function betGroupName(code)
{
  for(let row of betGroups) {
    if(row.code === code) {
      return row.name;
    }
  }

  return '';
}

export function kickTimeDisplay(isHT, color = false)
{
  for(let row of kickTime) {
    if(row.ht === isHT) {
      if(color){
        return row.color
      }
      else {
        return row.name
      }
    }
  }

  return ''
}

export function playTypePriceDisplay(code, getColor = false)
{
  for(let row of playTypePrice) {
    if(row.code === code) {
      if(getColor){
        return row.color
      }
      else {
        return i18n.t(row.name)
      }
    }
  }

  return '';
}

export function liveKickTime(txtTime)
{
  //EX txtTime : 1H 45, 2H 45, H.Time
  const txtArr = txtTime.split(' ')
  if(txtArr.length === 2){
    const timeNumber = Number(txtArr[1])
    const gameTime = 45
    if(txtArr[0] === '1H'){
      return timeNumber > gameTime ? `${gameTime}+${(timeNumber-gameTime)}` : timeNumber
    }
    else if(txtArr[0] === '2H'){
      const timeFT = 90
      return (gameTime + timeNumber) > timeFT ? `${timeFT}+${((gameTime + timeNumber)-timeFT)}` : (gameTime + timeNumber)
    }
  }

  return txtTime
}

export function betTypeDisplay(playRow, lang = 'th')
{
  const type = getBetType(playRow.play_type)
  if(type.group === BET_GROUP_DP){
    if (lang == 'th'){
      return (playRow.b_big === type.team) ? '[ต่อ]' : '[รอง]'
    }else{
      return (playRow.b_big === type.team) ? '[T.]' : '[R.]'
    }
  }
  else if(type.group === BET_GROUP_OU){
    if (lang == 'th'){
      return type.team === 1 ? '[สูง]' : '[ต่ำ]'
    }else{
      return type.team === 1 ? '[Over]' : '[Under]'
    }
  }
  else if(type.group === BET_GROUP_1X2){

  }
  else if(type.group === BET_GROUP_OE){
    if (lang == 'th'){
      return type.team === 1 ? '[คี่]' : '[คู่]'
    }else{
      return type.team === 1 ? '[Odd]' : '[Even]'
    }
  }
  else {
    return '-'
  }
}

export function betTeamDisplay(playRow, lang = 'th')
{
  const type = getBetType(playRow.play_type)
  if(type.team === 1){
    return playRow.ball_team1_th && lang == 'th' ? playRow.ball_team1_th : playRow.ball_team1_en;
  }
  else if(type.team === 2){
    return playRow.ball_team2_th && lang == 'th' ? playRow.ball_team2_th : playRow.ball_team2_en;
  }
  else {
    return type.group === BET_GROUP_1X2 ? 'Draw' : ''
  }
}

export function translatePrintSlip(lang)
{
  if (!['th','en'].includes(lang)){
    lang = 'en'
  }
  let translate = translateSlip.filter((trasSlip)=>trasSlip['lang'] == lang);
  return translate[0].text
}

export function htmlPrintDetail(res, formValues)
{
  const l = formValues
  const lang = translatePrintSlip(formValues)

  const data = res.data
  const divContents = (
    <div style={{overflow:'auto',height:'100%'}}>
      {Object.keys(data).map((r)=>{
        const row = data[r];
        return (
          <div key={r}>
            <div style={{padding: '10'}}>
              <table width={'100%'}>
                <tr>
                  <td>
                    <div style={{ height: "auto", maxWidth: 100, width: "100%" }}>
                      <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={`${process.env.REACT_APP_WEBSITE_URL}/check/${encodeURIComponent(window.btoa(row.bill_id))}`}
                        viewBox={`0 0 256 256`}
                      />
                    </div>
                  </td>
                  <td align="center">{res.mes_name}</td>
                </tr>
              </table>
            </div>
            <div style={{padding: '0 10'}}>
              <table width={'100%'}>
                <tr>
                  <td width={'65%'}>{lang['bill_id']} : {row.bill_id}</td>
                  <td width={'35%'} align="right">OTP : {row.b_otp}</td>
                </tr>
                <tr>
                  <td width={'65%'}>{lang['date']} : {dateTimeF(row.b_timestam)}</td>
                  <td width={'35%'} align="right">Line [{row.m_user.slice(-3)}]</td>
                </tr>
              </table>
            </div>
            <hr style={{borderTop: '2px dotted'}} />
            <div style={{padding: '0 10'}}>
              <table width={'100%'}>
                {Object.keys(data[row.bill_id]['bet_details']).map((play_id, inx)=>{
                  const play = data[row.bill_id]['bet_details'][play_id]
                  const type = getBetType(play.play_type)
                  return (
                    [<tr key={inx} style={{display: Object.keys(data[row.bill_id]['bet_details']).length > 1 ? 'none' : '' }}>
                      <td colSpan="2">{Number(play.b_big) === 1 ? '*' : ''}{l === 'th' ? (play.ball_team1_th ? play.ball_team1_th : play.ball_team1_en) : (play.ball_team1_en ? play.ball_team1_en : play.ball_team1_th)} -VS- {Number(play.b_big) === 2 ? '*' : ''}{l === 'th' ? (play.ball_team2_th ? play.ball_team2_th : play.ball_team2_en) : (play.ball_team2_en ? play.ball_team2_en : play.ball_team2_th)}</td>
                    </tr>,
                      <tr key={inx}>
                        <td colSpan="2">
                          [{play.play_code}] [{kickTimeDisplay(type.halftime)}] {(type.group === BET_GROUP_1X2) ? <>{betTeamDisplay(play)} {type.team > 0 &&<span> ({lang['win']})</span>}</>:<>{betTypeDisplay(play,l)} {betTeamDisplay(play,l)}</>}
                          {play.b_time_play && <span>&nbsp;&nbsp; {lang['live']} {play.b_score_live} {lang['time']} {liveKickTime(play.b_time_play)}'</span>}
                        </td>
                      </tr>,
                      <tr key={inx}>
                        <td scope="col">[{dateTimeF(play.ball_datetime, 'HH:mm')}]</td>
                        <td align="right">{(type.group !== BET_GROUP_1X2 && type.group !== BET_GROUP_OE) && <>[{play.play_bet}]</>}&nbsp;&nbsp;&nbsp; [{numberF(play.b_odds, 2)}]</td>
                      </tr>]
                  )
                })}
              </table>
            </div>
            <hr style={{borderTop: '2px dotted'}} />
            <div style={{padding: '0 10'}}>
              <table width={'100%'}>
                <tr>
                  <td>{lang['amount']} : {Object.keys(data[row.bill_id]['bet_details']).length} {lang['match']}</td>
                  <td align="right">{numberF(row.b_total,2)} {lang['baht']} </td>
                </tr>
                {/* <tr>
                  <td>{lang['discount']} : </td>
                  <td align="right">{numberF(row.b_pay,2)} {lang['baht']}</td>
                </tr>
                <tr>
                  <td>{lang['total']} : </td>
                  <td align="right">{numberF(row.b_total-row.b_pay,2)} {lang['baht']}</td>
                </tr> */}
                <tr>
                  <td>{lang['payout']} :</td>
                  <td align="right">{numberF((row.b_status === BILL_STATUS_WAIT ?row.b_total*Object.keys(data[row.bill_id]['bet_details']).reduce((res,be)=>{return res*data[row.bill_id]['bet_details'][be]['b_odds']},1):row.b_bonus),2)}  {lang['baht']}</td>
                </tr>
              </table>
            </div>
            <hr style={{borderTop: '2px dotted'}} />
            <div style={{padding: '0 10'}}>
              <center>{res.mes_bill}</center>
            </div>
            <div className="pagebreak" style={{pageBreakAfter:'always'}}> </div>
          </div>
        )
      })}
    </div>
  )

  const a = window.open('', 'Title', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=700,height=900');
  a.document.write('<!DOCTYPE html><html><body>');
  a.document.body.innerHTML = ReactDOMServer.renderToString(divContents)
  a.document.write('</body></html>');
  a.document.write(`<script> window.addEventListener('load', function () { window.print(); }) </script>`);
  a.document.close()
  // a.focus()
  // a.print()
  // a.close()
}
