import React, {createContext, useContext, useState} from 'react';
import {toast} from "react-toastify";
import AgentService from "../services/Agent.service";
import {useEffect} from "react";
import {clearLocalStorage, empty} from "../common/util/functions";
import moment from 'moment/moment';

export const AuthContext = createContext()
function AuthContextProvider(props) {
  const agentService = new AgentService()
  const [activeUser, setActiveUser] = useState(null)
  const [loadingProfile , setLoadingProfile] = useState(true)
  const [currentTime, setCurrentTime] = useState(moment());

  var countdownUserInfoDefault = 60
  var countdownUserInfo = countdownUserInfoDefault

  useEffect(()=>{
    fetchUserData()
    const interval = setInterval(() => {
        setCurrentTime(moment());
        if(countdownUserInfo == 0)
        {
            fetchUserData()
            countdownUserInfo = countdownUserInfoDefault
        }
        countdownUserInfo-=1
    }, 1000);
    return () => clearInterval(interval);

  }, [])

  const fetchUserData = async () => {
    const token = localStorage.getItem('X_AG_CUR_TOKEN')
    if (!empty(token)) {
      // setLoadingProfile(true)
      await agentService.myProfile().then(res => {
        setLoadingProfile(false)
        if (res.success) {
          setActiveUser(res.data)
        } else {

          toast.error(res.message,{
            onClose: () => {
              clearLocalStorage()
              setActiveUser(null)
            }
          })

        }
      })
    }
    else {
      setLoadingProfile(false)
    }
  }

  const logout = async () => {
    clearLocalStorage()
    setActiveUser(null)
  }

  const value = {activeUser, loadingProfile, fetchUserData, logout}
  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  )

}

function useAuthContext() {
    const context = useContext(AuthContext)
    /*if (context === undefined) {
        throw new Error('usePrefix must be used within a AuthProvider')
    }*/
    return context
}


export {useAuthContext}

export default AuthContextProvider;
