import axios from 'axios';
import {clearLocalStorage, empty} from "../common/util/functions";
import i18n from '../i18n'

export default class AbstractService
{
  ROOT_API = process.env.REACT_APP_API_URL;
  APP_LANG = i18n.language;
  get(path, params)
  {
    const token = localStorage.getItem('X_AG_CUR_TOKEN')
    const self = this;
    return new Promise((resolve) => {
      axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + token,
          'Accept-Language' : self.APP_LANG
        }
      })
        .get(self.ROOT_API + path, {params: params})
        .then(resp => {
          resp.data.success = true;
          resolve(resp.data);
        })
        .catch(function (error) {
          const result = AbstractService.errorMessage(error);
          resolve(result);
        });
    });
  }

  post(path, params, files)
  {
    const token = localStorage.getItem('X_AG_CUR_TOKEN')
    const self = this;
    if(!empty(files)){
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(files[i].param_name, files[i].file, files[i].file.name);
      }
      if(!empty(params)) {
        formData.append('data', JSON.stringify(params));
      }

      params = formData;
    }

    return new Promise((resolve) => {
      axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + token,
          'Accept-Language' : self.APP_LANG
        }
      })
        .post(self.ROOT_API + path, params)
        .then(resp => {
          resp.data.success = true;
          resolve(resp.data);
        })
        .catch(function (error) {
          const result = AbstractService.errorMessage(error);
          resolve(result);
        });
    });
  }

  put(path, params)
  {
    const token = localStorage.getItem('X_AG_CUR_TOKEN')
    const self = this;
    return new Promise((resolve) => {
      axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer ' + token,
          'Accept-Language' : self.APP_LANG
        }
      })
        .put(self.ROOT_API + path, params)
        .then(resp => {
          resp.data.success = true;
          resolve(resp.data);
        })
        .catch(function (error) {
          const result = AbstractService.errorMessage(error);
          resolve(result);
        });
    });
  }

  static errorMessage(error)
  {
    let text = '';
    let status = null;
    if(error){
      status = error.response.status;
      text = error.response.statusText;
      //Service can return error message in json format
      if(status >= 300 && status < 400){
        text = 'Server return redirect status code: ' + status;
      }
      else if(status >= 400 && status < 500){
        if(status === 400){
          text = 'Requested service with parameters, can not be processed.';
          if(!empty(error.response.data.message)){
            text = error.response.data.message;
          }
        }
        else if(status === 401){
          text = 'User not passed authentication.';
        }
        else if(status === 403){
          text = 'No permission to access requested data.';
          alert('Session expired')
          clearLocalStorage()
          window.location.href = '/login'
        }
        else if(status === 404){
          text = 'Requested service not existing : ' + error.response.config.url;
        }
      }
      else if(status >= 500){
        if(status === 500){
          text = 'ERROR CODE:  ' + status + '. STATUS TEXT: ' + error.response.status;
          if(!empty(error.response.data.message)){
            text = error.response.data.message;
          }
        }
        else if(status === 501){
          text = 'Requested service is not implemented.';
        }
        else if(status === 502){
          text = 'Bad Gateway.';
        }
        else if(status === 503){
          text = 'Service Unavailable.';
        }
        else if(status === 504){
          text = 'Gateway Timeout.';
        }
        else if(status === 505){
          text = 'HTTP Version Not Supported.';
        }
      }

      if(text === '') {
        text = 'ERROR CODE: ' + status + '  STATUS TEXT: ' + (!empty(error.statusText) ? error.statusText : error.message);
      }

      if(!empty(error.response.data) && !empty(error.response.data.message)){
        text = error.response.data.message;
      }
    }

    return {
      success: false,
      message: text,
      //message: error.response.data.message ?? error.response.data.detail[0].msg ?? error.response.data.detail ?? error.message ?? text,
      status: status
    };
  }
}
