export const LABEL_TH = {
  'Username': 'ชื่อผู้ใช้',
  'Password': 'รหัสผ่าน',
  'Login': 'เข้าสู่ระบบ',
  'Home': 'หน้าหลัก',
  'RESULT': 'ผลลัพธ์',
  'Menu Bill': 'บิล',
  'Bet List': 'รายการบิล',
  'Bet Confirm': 'รอยืนยัน',
  'Outstanding': 'สรุปบิลรอผล',
  'Score': 'ผลการแข่งขัน',
  'Transfer':'โอนเงิน',
  'REPORTS': 'รายงาน',
  'Winloss': 'ชนะ/แพ้',
  'Winloss (HDP/STEP)': 'ชนะ/แพ้ (HDP/STEP)',
  'Menu Payout': 'ประวัติขึ้นรางวัล',
  'Canceled': 'ประวัติยกเลิกบิล',
  'MEMBER INFO':'ข้อมูลผู้ใช้',
  'Member':'สมาชิก',
  'Create Member':'สร้างสมาชิก',
  'Member List':'รายการสมาชิก',
  'Commission':'คอมมิชชั่น',
  'com':'คอม',
  'Com Total':'คอมฯสุทธิ์',
  'Agent':'เอเย่นต์',
  'Create Agent':'สร้างเอเย่นต์',
  'Agent List':'รายการเอเย่นต์',
  'Sub Agent':'เอเย่นต์ย่อย',
  'SYSTEM':'ระบบ',
  'Config':'ตั้งค่า',
  'Log History':'ประวัติเข้าสู่ระบบ',
  'Link':'ลิ้งค์',
  'Language': 'ภาษา',
  'Account':'บัญชีผู้ใช้',
  'Credit Limit':'วงเครดิต',
  'Credit Balance':'เครดิตคงเหลือ',
  'Change Password':'เปลี่ยนรหัสผ่าน',
  'Logout': 'ออกจากระบบ',
  'Date':'วันที่',
  'Datetime':'วันเวลา',
  'IP':'ที่อยู่ IP',
  'Message':'ข้อความ',
  'Quick bet':'เดิมพันด่วน',
  'All bet':'เดิมพันทั้งหมด',
  'Single bet':'เต็ง',
  'Mix bet':'สเต็ป',
  'Running':'รอผล',
  'Finish':'เสร็จสิ้น',
  'Cancel':'ยกเลิก',
  'Search': 'ค้นหา',
  'Bill ID':'รหัสบิล',
  'Price':'ราคา',
  'Details':'รายละเอียด',
  'Time':'เวลา',
  'Pay':'ราคาจ่าย',
  'Accept By':'ผู้รับบิล',
  'Pay By':'ผู้จ่ายบิล',
  'Get a bill':'สถานะรับบิล',
  'Del':'ยกเลิก',
  'Bet total':'ทั้งหมด',
  'Sum Pay':'จ่ายแล้ว',
  'Sum UnPay':'ยังไม่จ่าย',
  'Win Total':'รวมจ่าย',
  'Quick bet title':'แทงด่วนแบบรวม',
  'Example Quick bet':`ใส่ข้อมูลได้สูงสุด 50 ชุด
แต่ละชุดจะต้องอยู่คนละบรรทัด
ใช้เครื่องหมาย * หรือ . คั่น
ปิดท้ายด้วย = จำนวนเงิน
ตัวอย่าง
3*7*19*27=50
51*55*59=50
--------------
ตัวอย่าง
3.7.19.27=50
51.55.59=50`,
  'Clear':'ล้าง',
  'Bet':'เดิมพัน',
  'Alert Cancel Bill':'ต้องการยกเลิกบิล {{bill_id}} หรือไม่?',
  'Okay':'ตกลง',
  'Check OTP Code':'ตรวจสอบ OTP CODE',
  'Check OTP Code Alert':'กรุณาตรวจสอบ OTP CODE ก่อนทำรายการต่อไป',
  'Accept Pay A01':'สถานะ {{b_status}} ไม่สามารถชำระได้',
  'Accept Pay A02':'บิล {{bill_id}} ถูกชำระเรียบร้อยแล้ว',
  'Alert Pay Bill':'ต้องการจ่ายบิล {{bill_id}} หรือไม่?',
  'Print Bill':'กรุณาเลือกบิลรายการ',
  'Choose Language for Print':'เลือกภาษาสำหรับการพิมพ์',
  'Refresh in':'รีโหลดใน',
  'Choose Member':'เลือกสมาชิก',
  'Discount':'ส่วนลด',
  'Total Amount':'สุทธิ',
  'Type':'ประเภท',
  'Live Check':'ตรวจบอลไลฟ์',
  'Choose':'เลือก',
  'Live':'บอลสด',
  'Normal':'บอลปกติ',
  'Billing':'จำนวน',
  'Total':'ทั้งหมด',
  'Unit':'คู่',
  'Baht':'บาท',
  'Print & Accept':'พิมพ์ & รับบิล',
  'Turn Over':'เทิร์นโอเวอร์',
  'Bet Accept':'ยอดคิดได้เสีย',
  'Company':'บริษัท',
  'Member Share':'ยอดส่งออก',
  'Sum Comm':'คอมมิชชั่น',
  'Sum Total':'รวม',
  'Agent Share':'ยอดถือสู้',
  'Detail/Event':'รายละเอียด/เหตุการณ์',
  'Amount':'จำนวน',
  'Status':'สถานะ',
  'Amount Total':'รวมทั้งสิ้น',
  'Pay Total':'จ่ายทั้งสิ้น',
  'Bet Amount':'จำนวนเดิมพัน',
  'Est. /Payout':'ประมาณ / จ่าย',
  'PARLAY Match':'สเต็ป {{bill_play_length}} คู่',
  'ID':'รหัส',
  'tb_ft': 'เต็มเวลา',
  'tb_1h': 'ครึ่งแรก',
  'tb_home': 'เจ้าบ้าน',
  'tb_away': 'ทีมเยือน',
  'All':'ทั้งหมด',
  'Want To Clear Credit?':'ต้องการเครียร์ยอด หรือไม่?',
  'Transfer from choose': 'โอนเงินที่เลือก',
  'Credit':'เครดิต',
  'Credit Bet':'เครดิตเดิมพัน',
  'Sum Bonus':'โบนัส',
  'Select Agent Type':'เลือกประเภทเอเย่นต์',
  'Sub-Agent':'เอเย่นต์ย่อย',
  'Log ID':'รหัสประวัติ',
  'Description':'รายละเอียด',
  'By':'โดย',
  'Pay All':'จ่ายทั้งหมด',
  'Member Information':'ข้อมูลสมาชิก',
  'Member Name':'ชื่อสมาชิก',
  'Member Username':'ชื่อผู้ใช้สมาชิก',
  'Min': 'ขั้นต่ำ',
  'Max': 'สูงสุด',
  'Odds line':'อัตราจ่าย',
  'Share':'หุ้น',
  'Live Bet':'บอลสด',
  'Price type':'ประเภทราคา',
  '1X2 / odd even':'1X2 / คู่ คี่',
  'to':'ถึง',
  'Full Max/Bill':'จ่ายสูงสุด/บิล',
  'Single bet min':'เดิมพันเต็งขั้นต่ำ',
  'Single bet max/Bill':'เดิมพันเต็งสูงสุด/บิล',
  'Single bet max/Match': 'เดิมพันเต็งสูงสุด/คู่',
  'Mix bet min':'เดิมพันสเต๊ปขั้นต่ำ',
  'Mix bet max':'เดิมพันสเต๊ปสูงสุด',
  'Num':'คู่',
  'Save':'บันทึก',
  'Back to list':'กลับสู่รายการ',
  'Open':'เปิด',
  'Close':'ปิด',
  'Bet Off':'ปิดเดิมพัน',
  'Bet On':'เปิดเดิมพัน',
  'No Comm':'ไม่มีคอมมิชชั่น',
  'Log':'ประวัติ',
  'Last login':'เข้าสู่ระบบล่าสุด',
  'Create at':'สร้างเมื่อ',
  'Edit':'แก้ไข',
  'Agent Information':'ข้อมูลเอเย่นต์',
  'Agent Name':'ชื่อเอเย่นต์',
  'Agent Username':'ชื่อผู้ใช้เอเย่นต์',
  'Keep share': 'เก็บหุ้น',
  'Sunday':'วันอาทิตย์',
  'Monday':'วันจันทร์',
  'Tuesday':'วันอังคาร',
  'Wednesday':'วันพุธ',
  'Thursday':'วันพฤหัสบดี',
  'Friday':'วันศุกร์',
  'Saturday':'วันเสาร์',
  'Bill Delete':'ยกเลิกบิล',
  'Add sub agent':'สร้างเอเย่นต์ย่อย',
  'Sub agent name':'ชื่อเอเย่นต์ย่อย',
  'Sub agent username':'ชื่อผู้ใช้เอเย่นต์ย่อย',
  'Note':'โน๊ต',
  'Sub Agent Information':'ข้อมูลสมาชิกย่อย',
  'Slip':'สลิป',
  'Logo URL':'ลิ้งค์โลโก้',
  'Size height 80px':'ขนาดสูงสุด 80px',
  'Upload logo':'อัพโหลดโลโก้',
  'Shop name':'ชื่อร้าน',
  'Below message':'ข้อความด้านล่าง',
  'Bill':'โพย',
  'Copy':'คัดลอก',
  'Get OTP':'ค้นหา OTP',
  'Check bill':'ตรวจสอบบิลรายการ',
  'Show':'แสดง',
  'items':'รายการ',
  'Loading': 'กำลังโหลด',
  'Data not found': 'ไม่พบข้อมูล',
  'MEMBER_STATUS_NORMAL':'ปกติ',
  'MEMBER_STATUS_NO_BET':'ระงับการเดิมพัน',
  'MEMBER_STATUS_LOCK':'ระงับการใช้งาน',
  'MEMBER_ACCEPT_STATUS_ONLINE':'ออนไลน์',
  'MEMBER_ACCEPT_STATUS_SHOP':'หน้าร้าน',
  'MEMBER_ACCEPT_STATUS_SHOP_AND_AUTO':'หน้าร้าน+อัตโนมัติ',
  'BILL_ACCEPT_STATUS_CANCEL':'ยกเลิก',
  'BILL_ACCEPT_STATUS_NORMAL':'ปกติ',
  'BILL_ACCEPT_STATUS_WAIT':'รอยืนยัน',
  'BILL_ACCEPT_STATUS_LIVE_CHECK':'กำลังตรวจ',
  'BILL_ACCEPT_STATUS_LIVE_ACCEPT':'รอยืนยัน',
  'BILL_ACCEPT_STATUS_LIVE_REJECT':'ไม่ยอมรับ',
  'AGENT_STATUS_NORMAL':'ปกติ',
  'AGENT_STATUS_NO_BET':'ระงับการเดิมพัน',
  'AGENT_STATUS_LOCK':'ระงับการใช้งาน',
  'AGENT_BET_BILL_DEL_ON':'เปิดยกเลิก',
  'AGENT_BET_BILL_DEL_OFF':'ปิดยกเลิก',
  'SUB_AGENT_STATUS_NORMAL':'ปกติ',
  'SUB_AGENT_STATUS_CLOSE':'ปิด',
  'TRANSFER_TYPE_DEPOSIT':'ฝาก',
  'TRANSFER_TYPE_WITHDRAW':'ถอน',
  'TRANSFER_TYPE_ACCEPT_BILL':'รับบิล',
  'TRANSFER_TYPE_PAYOUT':'จ่าย',
  'BILL_STATUS_WIN': 'ได้',
  'BILL_STATUS_PAYBACK': 'คืนทุน',
  'BILL_STATUS_LOSE': 'เสีย',
  'BILL_STATUS_CANCEL': 'ยกเลิก',
  'BILL_STATUS_WAIT': 'รอผล',
  'PLAY_STATUS_WIN': 'ได้เต็ม',
  'PLAY_STATUS_WIN_HALF': 'ได้ครึ่ง',
  'PLAY_STATUS_PAYBACK': 'คืนทุน',
  'PLAY_STATUS_LOSE': 'เสียเต็ม',
  'PLAY_STATUS_LOSE_HALF': 'เสียครึ่ง',
  'PLAY_STATUS_CANCEL': 'ยกเลิก',
  'PLAY_STATUS_WAIT': 'รอผล',
  'SCORE_STATUS_COMPLETED': 'เสร็จสมบูรณ์',
  'SCORE_STATUS_RUNNING': 'รอผล',
  'SCORE_STATUS_CANCEL': 'ยกเลิก',
  'SCORE_STATUS_1H_CANCEL': 'ยกเลิก ครึ่งเวลา',
  'SCORE_STATUS_FT_CANCEL': 'ยกเลิก เต็มเวลา',
  'Current Password':'รหัสผ่านเก่า',
  'New Password':'รหัสผ่านใหม่',
  'Confirm Password':'ยืนยันรหัสผ่าน',
  'Service_Support':'บริการ',
  'Payout': 'จ่าย',
  'Summary': 'สรุป',
  'Action':'จัดการ',
  'Transaction history':'ประวัติทำรายการ',
  'Cut-Off':'ตัดยอด/ส่งเงิน',
  'Transaction Manual':'ประวัติเติมมือ-ถอนมือ',
  'Net balance': 'คงเหลือสุทธิ',
  'Notification': 'การแจ้งเดือน',
  'Line token': 'ไลน์ Token',
  'Cancel and accept payment': 'ยกเลิกบิล และ ยอมรับชำระเงิน',
  'Paid': 'ชำระเรียบร้อย',
  'Make a payment': 'ทำการชำระ',
  'All status': 'สถานะทั้งหมด',
  'Copy-Member':'คัดลอกสมาชิก',
  'Copy-Agent':'คัดลอกเอเย่นต์',
  'input-error-1':'กรอกข้อมูลไม่ถูกต้อง',
  'Add payout money':'เพิ่มค่าใช้จ่าย',
  'List payout money':'รายการค่าใช้จ่าย',
  'Get paid':'รายรับ',
  'Payout Money':'รายจ่าย',
  'Pendding':'รายการรอผล',
  'Tang': 'ตังค์',
  'Not Pay': 'ยังไม่จ่าย',
  'Not pay all': 'ไม่จ่ายทั้งหมด',
  'Day ago': 'วันที่ผ่านมา',
  'Days ago': 'วันที่ผ่านมา',
  'more than days' : 'มากกว่า {{day}} วัน',
  'Modify credit Member/Agent' : 'แก้ไขเครดิต สมาชิก/เอเย่นต์',
  'match_duplicate':'กรุณาเลือกทีมที่ไม่ซ้ำกัน สำหรับการเดิมพันแบบสเต็ป',
  'Check the code when paste code or press "="':'ตรวจสอบรหัสเมื่อ วางชุดรหัส หรือ กด "=" เพื่อใส่ราคา',
  'Code Checking':'กำลังตรวจสอบรหัส',
  'Payout Bill':'บิลขึ้นเงิน'
}
