import AbstractService from "./Abstract.service";

export default class AgentService extends AbstractService
{
  create(data)
  {
    return this.post('/agent/create', data);
  }

  update(data)
  {
    return this.post('/agent/edit', data);
  }

  find(data)
  {
    return this.get('/agent/list', data);
  }

  info(data)
  {
    return this.post('/agent/info', data);
  }

  checkEdit(data)
  {
    return this.get('/agent/check/edit-agent', data);
  }

  updateStatus(data)
  {
    return this.post('/agent/update-status', data);
  }

  updateAcceptBill(data)
  {
    return this.post('/agent/update-accept-bill', data);
  }

  updateDiscount(data)
  {
    return this.post('/agent/update-discount', data);
  }

  createSub(data)
  {
    return this.post('/agent/sub', data);
  }

  updateSub(data)
  {
    return this.put('/agent/sub', data);
  }

  findSub(data)
  {
    return this.get('/agent/sub', data);
  }

  getCredit(data)
  {
    return this.get('/agent/get/credit', data);
  }

  myProfile()
  {
    return this.get('/agent/my-profile');
  }

  updateCredit(data)
  {
    return this.post('/agent/update-credit', data);
  }

  copyAgent(data)
  {
    return this.post('/agent/copy-agent', data);
  }

  findAllPayment(data)
  {
    return this.get('/agent/all-payment', data)
  }

  findAllAcceptPay(data)
  {
    return this.get('/agent/all-payment/accept-pay', data)
  }

  findNoAcceptPay(data)
  {
    return this.get('/agent/all-payment/no-accept-pay', data)
  }

  findAllPaymentCancel(data)
  {
    return this.get('/agent/all-payment/cancel', data)
  }

  findLogOTP(data)
  {
    return this.get('/agent/all-payment/otp', data)
  }

  findSubAgentBilling(data)
  {
    return this.get('/agent/sub/summary', data)
  }

  findBillingDetails(data)
  {
    return this.get('/agent/sub/details', data)
  }

  sublistName()
  {
    return this.get('/agent/sub/list-name')
  }

  addPayoutMoney(data)
  {
    return this.post('/agent/sub/add-payout-money',data)
  }

  listPayoutMoney(data)
  {
    return this.get('/agent/sub/list-payout-money', data)
  }

  listTransactionManual(data)
  {
    return this.get('/transaction/manual-history', data)
  }

}
