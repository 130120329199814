import {cifGb, cifKh, cifLa, cifMm, cifTh} from "@coreui/icons";


export const SA_OPEN = [
  {key:1,active:0},//Bill
  {key:2,active:0},//Soccer
  {key:3,active:0},//Winloss
  {key:4,active:0},//Member
  {key:5,active:0},//Config
  {key:6,active:0}//OTP
]

export const DATE_FORMAT_DB = 'YYYY-MM-DD';
export const DATETIME_FORMAT_DB = 'YYYY-MM-DD HH:mm:ss';

export const DATE_FORMAT_DB_MG = 'DD-MM-YYYY';
export const DATETIME_FORMAT_DB_MG = 'DD-MM-YYYY HH:mm:ss';

export const DATE_FORMAT_DISPLAY = 'DD/MM/YYYY';
export const DATETIME_FORMAT_DISPLAY = 'DD/MM/YYYY HH:mm:ss';
export const DATETIME_NO_SECOND_FORMAT_DISPLAY = 'DD/MM/YYYY HH:mm';

export const NO_SCORE_DISPLAY = '?-?'

export const MEMBER_STATUS_NORMAL = 1;
export const MEMBER_STATUS_NO_BET = 2;
export const MEMBER_STATUS_LOCK = 0;

export const BILL_ACCEPT_STATUS_CANCEL = 0;
export const BILL_ACCEPT_STATUS_NORMAL = 1;
export const BILL_ACCEPT_STATUS_WAIT = 3;
export const BILL_ACCEPT_STATUS_LIVE_CHECK = 4;
export const BILL_ACCEPT_STATUS_LIVE_ACCEPT = 5;
export const BILL_ACCEPT_STATUS_LIVE_REJECT = 6;

export const MEMBER_ACCEPT_STATUS_SHOP = 0;
export const MEMBER_ACCEPT_STATUS_ONLINE = 1;
export const MEMBER_ACCEPT_STATUS_SHOP_AND_AUTO = 2;

export const AGENT_STATUS_NORMAL = 1;
export const AGENT_STATUS_NO_BET = 2;
export const AGENT_STATUS_LOCK = 0;

export const AGENT_BET_BILL_DEL_ON = 1;
export const AGENT_BET_BILL_DEL_OFF = 0;

export const SUB_AGENT_STATUS_NORMAL = 1;
export const SUB_AGENT_STATUS_CLOSE = 0;

export const TRANSFER_TYPE_DEPOSIT = 1;
export const TRANSFER_TYPE_WITHDRAW = 2;
export const TRANSFER_TYPE_ACCEPT_BILL = 3;
export const TRANSFER_TYPE_PAYOUT = 4;
export const TRANSFER_TYPE_TRANSFER = 5;
export const TRANSFER_TYPE_IN_MONEY = 6;
export const TRANSFER_TYPE_OUT_MONEY = 7;

export const BILL_STATUS_WIN = 1;
export const BILL_STATUS_PAYBACK = 2;
export const BILL_STATUS_LOSE = 3;
export const BILL_STATUS_CANCEL = 4;
export const BILL_STATUS_WAIT = 5;

export const PLAY_STATUS_WIN = 1;
export const PLAY_STATUS_WIN_HALF = 2;
export const PLAY_STATUS_PAYBACK = 3;
export const PLAY_STATUS_LOSE = 4;
export const PLAY_STATUS_LOSE_HALF = 5;
export const PLAY_STATUS_CANCEL = 6;
export const PLAY_STATUS_WAIT = 7;

export const SCORE_STATUS_COMPLETED = 1;
export const SCORE_STATUS_RUNNING = 2;
export const SCORE_STATUS_CANCEL = 3;
export const SCORE_STATUS_1H_CANCEL = 4;
export const SCORE_STATUS_FT_CANCEL = 5;

export const BET_TYPE_FT_HOME = 1;
export const BET_TYPE_FT_AWAY = 2;
export const BET_TYPE_FT_OVER = 3;
export const BET_TYPE_FT_UNDER = 4;
export const BET_TYPE_FT_1X2_1 = 5;
export const BET_TYPE_FT_1X2_2 = 6;
export const BET_TYPE_FT_1X2_X = 7;
export const BET_TYPE_FT_ODD = 8;
export const BET_TYPE_FT_EVEN = 9;
export const BET_TYPE_HT_HOME = 10;
export const BET_TYPE_HT_AWAY = 11;
export const BET_TYPE_HT_OVER = 12;
export const BET_TYPE_HT_UNDER = 13;
export const BET_TYPE_HT_1X2_1 = 14;
export const BET_TYPE_HT_1X2_2 = 15;
export const BET_TYPE_HT_1X2_X = 16;
export const BET_TYPE_HT_ODD = 17;
export const BET_TYPE_HT_EVEN = 18;

export const BET_GROUP_DP = 1;
export const BET_GROUP_OU = 2;
export const BET_GROUP_1X2 = 3;
export const BET_GROUP_OE = 4;

export const ODDS_LINE_NONE = 0;
export const ODDS_LINE_A = 1;
export const ODDS_LINE_B = 2;
export const ODDS_LINE_C = 3;
export const ODDS_LINE_E = 4;

export const PLAY_TYPE_PRICE_MY = 1;
export const PLAY_TYPE_PRICE_EU = 2;
export const PLAY_TYPE_PRICE_HK = 3;
export const PLAY_TYPE_PRICE_ID = 4;
export const PLAY_TYPE_PRICE_STEP = 5;

export const supportedLngs = Object.freeze(
  [
    {code: 'en', name: 'English', flag: cifGb},
    {code: 'th', name: 'ไทย', flag: cifTh},
    {code: 'la', name: 'ລາວ', flag: cifLa},
    {code: 'mm', name: 'မြန်မာ', flag: cifMm},
    {code: 'kh', name: 'កម្ពុជា', flag: cifKh}
  ]
);

export const memberStatus = Object.freeze(
  [
    {code: MEMBER_STATUS_NORMAL, name:'MEMBER_STATUS_NORMAL', color: 'success'},
    {code: MEMBER_STATUS_NO_BET, name: 'MEMBER_STATUS_NO_BET', color: 'warning'},
    {code: MEMBER_STATUS_LOCK, name: 'MEMBER_STATUS_LOCK', color: 'danger'},
  ]
);

export const memberAcceptStatus = Object.freeze(
  [
    {code: MEMBER_ACCEPT_STATUS_ONLINE, name: 'MEMBER_ACCEPT_STATUS_ONLINE', color: 'success'},
    {code: MEMBER_ACCEPT_STATUS_SHOP, name: 'MEMBER_ACCEPT_STATUS_SHOP', color: 'warning'},
    {code: MEMBER_ACCEPT_STATUS_SHOP_AND_AUTO, name: 'MEMBER_ACCEPT_STATUS_SHOP_AND_AUTO', color: 'Primary'},
  ]
);

export const billAcceptStatus = Object.freeze(
  [
    {code: BILL_ACCEPT_STATUS_CANCEL, name:'BILL_ACCEPT_STATUS_CANCEL', color:'danger'},
    {code: BILL_ACCEPT_STATUS_NORMAL, name:'BILL_ACCEPT_STATUS_NORMAL', color:'success'},
    {code: BILL_ACCEPT_STATUS_WAIT, name:'BILL_ACCEPT_STATUS_WAIT', color:'warning'},
    {code: BILL_ACCEPT_STATUS_LIVE_CHECK, name:'BILL_ACCEPT_STATUS_LIVE_CHECK', color:'warning'},
    {code: BILL_ACCEPT_STATUS_LIVE_ACCEPT, name:'BILL_ACCEPT_STATUS_LIVE_ACCEPT', color:'warning'},
    {code: BILL_ACCEPT_STATUS_LIVE_REJECT, name:'BILL_ACCEPT_STATUS_LIVE_REJECT', color:'danger'}
  ]
);

export const agentStatus = Object.freeze(
  [
    {code: AGENT_STATUS_NORMAL, name: 'AGENT_STATUS_NORMAL', color: 'success'},
    {code: AGENT_STATUS_NO_BET, name: 'AGENT_STATUS_NO_BET', color: 'warning'},
    {code: AGENT_STATUS_LOCK, name: 'AGENT_STATUS_LOCK', color: 'danger'},
  ]
);

export const agentBetBillDelStatus = Object.freeze(
  [
    {code: AGENT_BET_BILL_DEL_ON, name: 'AGENT_BET_BILL_DEL_ON', color: 'success'},
    {code: AGENT_BET_BILL_DEL_OFF, name: 'AGENT_BET_BILL_DEL_OFF', color: 'warning'},
  ]
);

export const subAgentStatus = Object.freeze(
  [
    {code: SUB_AGENT_STATUS_NORMAL, name: 'SUB_AGENT_STATUS_NORMAL', color: 'success'},
    {code: SUB_AGENT_STATUS_CLOSE, name: 'SUB_AGENT_STATUS_CLOSE', color: 'danger'},
  ]
);

export const transferType = Object.freeze(
  [
    {code: TRANSFER_TYPE_DEPOSIT, name: 'TRANSFER_TYPE_DEPOSIT', color: 'success'},
    {code: TRANSFER_TYPE_WITHDRAW, name: 'TRANSFER_TYPE_WITHDRAW', color: 'warning'},
    {code: TRANSFER_TYPE_ACCEPT_BILL, name: 'TRANSFER_TYPE_ACCEPT_BILL', color: 'info'},
    {code: TRANSFER_TYPE_PAYOUT, name: 'TRANSFER_TYPE_PAYOUT', color: 'primary'},
    {code: TRANSFER_TYPE_TRANSFER, name: 'TRANSFER_TYPE_TRANSFER', color: 'primary'},
    {code: TRANSFER_TYPE_IN_MONEY, name: 'TRANSFER_TYPE_IN_MONEY', color: 'info'},
    {code: TRANSFER_TYPE_OUT_MONEY, name: 'TRANSFER_TYPE_OUT_MONEY', color: 'primary'},
  ]
);

export const billStatus = Object.freeze(
  [
    {code: BILL_STATUS_WIN, name: 'BILL_STATUS_WIN', color: 'success'},
    {code: BILL_STATUS_PAYBACK, name: 'BILL_STATUS_PAYBACK', color: 'info'},
    {code: BILL_STATUS_LOSE, name: 'BILL_STATUS_LOSE', color: 'danger'},
    {code: BILL_STATUS_CANCEL, name: 'BILL_STATUS_CANCEL', color: 'warning'},
    {code: BILL_STATUS_WAIT, name: 'BILL_STATUS_WAIT', color: 'secondary'}
  ]
);

export const playStatus = Object.freeze(
  [
    {code: PLAY_STATUS_WIN, name: 'PLAY_STATUS_WIN', color: 'success'},
    {code: PLAY_STATUS_WIN_HALF, name: 'PLAY_STATUS_WIN_HALF', color: 'success'},
    {code: PLAY_STATUS_PAYBACK, name: 'PLAY_STATUS_PAYBACK', color: 'info'},
    {code: PLAY_STATUS_LOSE, name: 'PLAY_STATUS_LOSE', color: 'danger'},
    {code: PLAY_STATUS_LOSE_HALF, name: 'PLAY_STATUS_LOSE_HALF', color: 'danger'},
    {code: PLAY_STATUS_CANCEL, name: 'PLAY_STATUS_CANCEL', color: 'warning'},
    {code: PLAY_STATUS_WAIT, name: 'PLAY_STATUS_WAIT', color: 'secondary'}
  ]
);

export const scoreStatus = Object.freeze(
  [
    {code: SCORE_STATUS_COMPLETED, name: 'SCORE_STATUS_COMPLETED', color: 'success'},
    {code: SCORE_STATUS_RUNNING, name: 'SCORE_STATUS_RUNNING', color: 'warning'},
    {code: SCORE_STATUS_CANCEL, name: 'SCORE_STATUS_CANCEL', color: 'danger'},
    {code: SCORE_STATUS_1H_CANCEL, name: 'SCORE_STATUS_1H_CANCEL', color: 'danger'},
    {code: SCORE_STATUS_FT_CANCEL, name: 'SCORE_STATUS_FT_CANCEL', color: 'danger'},
  ]
);

export const betTypes = Object.freeze(
  [
    {code: BET_TYPE_FT_HOME, group: BET_GROUP_DP, halftime: false, team: 1},
    {code: BET_TYPE_FT_AWAY, group: BET_GROUP_DP, halftime: false, team: 2},
    {code: BET_TYPE_FT_OVER, group: BET_GROUP_OU, halftime: false, team: 1},
    {code: BET_TYPE_FT_UNDER, group: BET_GROUP_OU, halftime: false, team: 2},
    {code: BET_TYPE_FT_1X2_1, group: BET_GROUP_1X2, halftime: false, team: 1},
    {code: BET_TYPE_FT_1X2_2, group: BET_GROUP_1X2, halftime: false, team: 2},
    {code: BET_TYPE_FT_1X2_X, group: BET_GROUP_1X2, halftime: false, team: 0},
    {code: BET_TYPE_FT_ODD, group: BET_GROUP_OE, halftime: false, team: 1},
    {code: BET_TYPE_FT_EVEN, group: BET_GROUP_OE, halftime: false, team: 2},
    {code: BET_TYPE_HT_HOME, group: BET_GROUP_DP, halftime: true, team: 1},
    {code: BET_TYPE_HT_AWAY, group: BET_GROUP_DP, halftime: true, team: 2},
    {code: BET_TYPE_HT_OVER, group: BET_GROUP_OU, halftime: true, team: 1},
    {code: BET_TYPE_HT_UNDER, group: BET_GROUP_OU, halftime: true, team: 2},
    {code: BET_TYPE_HT_1X2_1, group: BET_GROUP_1X2, halftime: true, team: 1},
    {code: BET_TYPE_HT_1X2_2, group: BET_GROUP_1X2, halftime: true, team: 2},
    {code: BET_TYPE_HT_1X2_X, group: BET_GROUP_1X2, halftime: true, team: 0},
    {code: BET_TYPE_HT_ODD, group: BET_GROUP_OE, halftime: true, team: 1},
    {code: BET_TYPE_HT_EVEN, group: BET_GROUP_OE, halftime: true, team: 2},
  ]
);

export const betGroups = Object.freeze(
  [
    {code: BET_GROUP_DP, name: 'HANDICAP'},
    {code: BET_GROUP_OU, name: 'OVER/UNDER'},
    {code: BET_GROUP_1X2, name: '1X2'},
    {code: BET_GROUP_OE, name: 'ODD/EVEN'}
  ]
);

export const kickTime = Object.freeze(
  [
    {ht: false, name: 'FT', color: 'primary'},
    {ht: true, name: '1H', color: 'warning'},
  ]
);

export const oddsLineType = Object.freeze(
  [
    {code: ODDS_LINE_NONE, name: 'None', color: 'secondary'},
    {code: ODDS_LINE_A, name: 'A', color: 'primary'},
    {code: ODDS_LINE_B, name: 'B', color: 'warning'},
    {code: ODDS_LINE_C, name: 'C', color: 'success'},
    {code: ODDS_LINE_E, name: 'E', color: 'secondary'},
  ]
);

export const playTypePrice = Object.freeze(
  [
    {code: PLAY_TYPE_PRICE_MY, name: 'MY', color: 'light'},
    {code: PLAY_TYPE_PRICE_EU, name: 'EU', color: 'light'},
    {code: PLAY_TYPE_PRICE_HK, name: 'HK', color: 'light'},
    {code: PLAY_TYPE_PRICE_ID, name: 'ID', color: 'light'},
    {code: PLAY_TYPE_PRICE_STEP, name: 'STEP', color: 'light'},
  ]
);

export const translateSlip = Object.freeze(
  [
    {lang: 'th', text:{
        'bill_id':'เลขบิล',
        'date':'วันที่',
        'win':'ชนะ',
        'live':'สด',
        'time':'เวลา',
        'amount':'จำนวนเดิมพัน',
        'discount':'ส่วนลด',
        'total':'ยอดเงินสุทธิ',
        'payout':'ประมาณ / จ่าย',
        'match':  'คู่',
        'baht':'บ.'
      }
    },
    {lang: 'en', text:{
        'bill_id':'Bill No.',
        'date':'Date',
        'win':'Win',
        'live':'Live',
        'time':'Time',
        'amount':'Amount',
        'discount':'Discount',
        'total':'Total',
        'payout':'Est. /Payout',
        'match':  'Match',
        'baht':'฿'
      }
    }
  ]
);
