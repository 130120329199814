const dataConfig = {
  localSet : {
    "separator": " - ",
    "applyLabel": "ตกลง",
    "cancelLabel": "ยกเลิก",
    "fromLabel": "จาก",
    "toLabel": "ถึง",
    "monthNames": [  "มกราคม", "กุมภาพันธ์", "มีนาคม",  "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม" ],
    "daysOfWeek": ['อา','จ','อ','พ','พฤ','ศ','ส']
  },
  discount : {
    min_dis_1 : 0.00,
    max_dis_1 : 5.00,
    min_dis_step : 0,
    max_dis_step : 30,
  }
}

export default dataConfig;
