export const LABEL_EN = {
  // Status
  'tb_ft': 'Full time',
  'tb_1h': 'First half',
  'tb_home': 'Home',
  'tb_away': 'Away',
  'Menu Bill': 'Bill',
  'Menu Payout': 'Payout',
  'Example Quick bet':`Maximum data 50 set.
Each set must be on a different line.
Use * or . to separate.
End with = amount
Example
3*7*19*27=50
51*55*59=50
--------------
Example
3.7.19.27=50
51.55.59=50`,
  'MEMBER_STATUS_NORMAL':'Normal',
  'MEMBER_STATUS_NO_BET':'No Bet',
  'MEMBER_STATUS_LOCK':'Lock',

  'MEMBER_ACCEPT_STATUS_ONLINE':'Online',
  'MEMBER_ACCEPT_STATUS_SHOP':'Shop',
  'MEMBER_ACCEPT_STATUS_SHOP_AND_AUTO':'Shop+Auto',

  'AGENT_STATUS_NORMAL':'Normal',
  'AGENT_STATUS_NO_BET':'No Bet',
  'AGENT_STATUS_LOCK':'Lock',
  'AGENT_BET_BILL_DEL_ON':'Del On',
  'AGENT_BET_BILL_DEL_OFF':'Del Off',

  'SUB_AGENT_STATUS_NORMAL':'Normal',
  'SUB_AGENT_STATUS_CLOSE':'Close',
  'TRANSFER_TYPE_DEPOSIT':'Deposit',
  'TRANSFER_TYPE_WITHDRAW':'Withdraw',
  'TRANSFER_TYPE_PAYOUT':'Payout',

  'BILL_STATUS_WIN': 'Win',
  'BILL_STATUS_PAYBACK': 'Payback',
  'BILL_STATUS_LOSE': 'Lose',
  'BILL_STATUS_CANCEL': 'Cancel',
  'BILL_STATUS_WAIT': 'Wait result',

  'PLAY_STATUS_WIN': 'Win',
  'PLAY_STATUS_WIN_HALF': 'Win half',
  'PLAY_STATUS_PAYBACK': 'Payback',
  'PLAY_STATUS_LOSE': 'Lose',
  'PLAY_STATUS_LOSE_HALF': 'Lose half',
  'PLAY_STATUS_CANCEL': 'Cancel',
  'PLAY_STATUS_WAIT': 'Wait result',

  'BILL_ACCEPT_STATUS_CANCEL': 'Cancel',
  'BILL_ACCEPT_STATUS_NORMAL': 'Normal',
  'BILL_ACCEPT_STATUS_WAIT': 'Wait accept',
  'BILL_ACCEPT_STATUS_LIVE_CHECK': 'Checking',
  'BILL_ACCEPT_STATUS_LIVE_ACCEPT': 'Wait accept',
  'BILL_ACCEPT_STATUS_LIVE_REJECT': 'Reject',

  'SCORE_STATUS_COMPLETED': 'Completed',
  'SCORE_STATUS_RUNNING': 'Running',
  'SCORE_STATUS_CANCEL': 'Cancel',
  'SCORE_STATUS_1H_CANCEL': 'First half cancel',
  'SCORE_STATUS_FT_CANCEL': 'Full time cancel',

  'Service_Support':'Support',
  'Price':'Price',

  'Copy-Member':'Copy Member',
  'Copy-Agent':'Copy Agent',
  'input-error-1':'Incorrect information',

  'Add payout money':'Add payout money',
  'List payout money':'List payout money',
  'Get paid':'Get paid',
  'Payout Money':'Payout Money',
  'Pendding':'Pendding',
  'more than days' : 'More than {{day}} days'
}
