import React, { Component, Suspense, useEffect, useState } from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import 'bootstrap-daterangepicker/daterangepicker.css';
import './scss/style.scss'
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-toastify/dist/ReactToastify.css';
import DataContext from "./data/DataContext";
import dataConfig from "./data/config";
import {useAuthContext} from "./data/AuthContext";
import {CSpinner} from "@coreui/react";

const elLoading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App()
{
  const [loading, setLoading] = useState(false)
  const {loadingProfile, activeUser } = useAuthContext()

  const loadingUI = (status)=>{
    setLoading(status)
  }

  const state = {
    loading
  }

  return (
    <BrowserRouter>
      <Suspense fallback={elLoading}>
        <DataContext.Provider value={{...dataConfig,...state,loadingUI:loadingUI}}>
          {!loadingProfile ?
            <Routes>
              <Route exact path="/login" name="Login Page" element={<>
              {activeUser !== null ? <Navigate to={'/'} /> : <Login />}
              </>} />
              <Route path="*" name="Home" element={
                <>{activeUser !== null ?  <DefaultLayout /> : <Navigate to="/login" />}</>
              } />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
            </Routes>
            : <div className="loading-full-screen loading"><CSpinner color="danger"/></div>
          }
        </DataContext.Provider>
      </Suspense>
    </BrowserRouter>
  )

}

export default App
